body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

button {
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.hero {
  background-color: #f0f0f0;
  padding: 60px 20px;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.about, .solutions {
  padding: 60px 20px;
}

.about h2, .solutions h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.about ul, .solutions ul {
  list-style-type: none;
  padding: 0;
}

.about li, .solutions li {
  margin-bottom: 10px;
}

.solution-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.solution-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.solution-card h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
}
/* Solution styles */
.solution-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 30px 0;
  overflow: hidden;
}

.solution-image-wrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 8px 8px 0 0;
}

.solution-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.solution-card:hover .solution-image {
  transform: scale(1.05);
}

.solution-card h3 {
  margin: 0 0 15px 0;
}

.solution-card ul {
  list-style-type: none;
  padding: 0 20px;
  margin: 0;
  text-align: left;
  width: 100%;
}

.solution-card li {
  margin: 10px 0;
  padding-left: 20px;
  position: relative;
}

.solution-card li::before {
  content: "•";
  color: #0047AB;
  font-weight: bold;
  position: absolute;
  left: 0;
}

.features, .advantages, .team {
  padding: 60px 20px;
}

.features h2, .advantages h2, .team h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.feature-grid, .advantage-grid, .team-grid {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 20px;
}

.feature-card, .advantage-card, .team-member {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  width: 250px;
  min-width: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-card h3, .advantage-card h3, .team-member h3 {
  font-size: 1.3em;
  margin-bottom: 10px;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 20px;
}

.feature-icon-wrapper {
  background-color: #f0f7ff;
  border-radius: 50%;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-icon {
  color: #0047AB;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .feature-grid, .advantage-grid, .team-grid {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .feature-card, .advantage-card, .team-member {
    width: 100%; /* Display elements in a single column */
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .feature-grid {
    grid-template-columns: 1fr;
  }

  .solution-grid {
    grid-template-columns: 1fr;
  }
}

.team-member p {
  margin: 5px 0;
}

.dashboard-preview {
  padding: 60px 20px;
  background-color: #f0f0f0;
}

.dashboard-preview h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.dashboard-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.dashboard-item {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  width: 40%;
  max-width: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dashboard-item h3 {
  font-size: 1.3em;
  margin-bottom: 15px;
}

.dashboard-item img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .dashboard-item {
    width: 90%;
  }
}

header {
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.main-header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  box-sizing: border-box;
}

.logo-container {
  flex: 0 0 auto;
}

.logo {
  height: auto;
  width: 200px;
  max-width: 100%;
}

nav {
  flex: 1 1 auto;
  text-align: right;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

nav ul li {
  margin: 0 15px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }

  .logo-container {
    margin-bottom: 15px;
  }

  nav {
    width: 100%;
    text-align: center;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 5px 0;
  }
}

/* Update the hero styles */
.hero {
  background-color: #f0f0f0;
  padding: 60px 20px;
  text-align: center;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

/* Update the button styles */
.cta-button {
  background-color: #0047AB;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #003380;
}

/* Add responsive design for smaller screens */
@media (max-width: 768px) {
  .main-header {
    flex-direction: column;
    padding: 15px 2%;
  }

  .logo-container {
    margin-bottom: 15px;
  }

  nav {
    text-align: center;
    margin-bottom: 15px;
  }

  nav ul li {
    margin: 0 10px;
  }
}

.team {
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.team h2 {
  font-size: 2em;
  margin-bottom: 40px;
  text-align: center;
}

.team-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.team-member {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.team-member-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.team-member h3 {
  font-size: 1.3em;
  margin-bottom: 10px;
}

.team-member p {
  margin: 5px 0;
  color: #555;
}

/* Add responsive design for smaller screens */
@media (max-width: 768px) {
  .team-grid {
    flex-direction: column;
    align-items: center;
  }

  .team-member {
    width: 80%;
    max-width: 300px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Contact styles */
.contact {
  padding: 60px 20px;
  background-color: #f0f0f0;
  text-align: center;
}

.contact h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.contact p {
  margin-bottom: 20px;
}

.contact-email {
  font-size: 1.2em;
  color: #0047AB;
  text-decoration: none;
  font-weight: bold;
}

.contact-email:hover {
  text-decoration: underline;
}

/* Footer styles */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 0.9em;
}

/* Update the existing styles */
.main-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.cta-button {
  display: inline-block;
  text-decoration: none;
}

/* Add id attributes to sections for smooth scrolling */
#about, #solutions, #features, #advantages, #team, #contact {
  scroll-margin-top: 80px; /* Adjust this value based on your header height */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container {
  max-width: 1200px; /* Adjust this value as needed */
  width: 100%;
  margin: 0 auto;
  padding: 0 20px; /* Add some padding on the sides */
  box-sizing: border-box;
}

/* Update the header styles */
.main-header {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
  max-width: 1200px; /* Match the main-container max-width */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

/* Update the footer styles */
.footer {
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  margin-top: auto; /* Push footer to bottom */
}

/* Responsive design */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }

  .logo-container {
    margin-bottom: 15px;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 5px 0;
  }
}

.dashboard-images {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.dashboard-image {
  max-width: 35%;
  height: auto;
}

/* Make Smart Eldercare and Intelligent Building cards wider */
.solution-card[data-title="Smart Eldercare"],
.solution-card[data-title="Intelligent Building"] {
  width: 400px; /* Increased from 300px */
}
